@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: Poppins, sans-serif;
  color-scheme: light dark;
  color: rgb(255, 255, 255);
  background-color: #242424;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
