@import "styles/variables.scss";

#root {
  margin: 0 auto;
  padding: 0;
}

@keyframes appear {
  0% {
    transform: translate(-100%);
    background: white;
    color: rgb(51, 51, 51)
  }
  100% {
    transform: translateX(0);
    color: white;
    background: linear-gradient(45deg, #7223cf, pink);
  }
}

@keyframes balance {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(30deg);
  }
}

main {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-top: 20px;

  .h2-apresentation-text {
    font-size: 36px;
  }

  .h2-position-text {
    font-size: 30px;
    color: #B3B3B3
  }

  .h3-about-title {
    font-size: 24px;
  }

  .p-experience-text {
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
  }

  .h3-language-education {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
  }

  .p-education-text {
    font-size: 20px;
    line-height: 36px;
    font-weight: 400;
    color: #B3B3B3
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    width: 100%;

    .menu-options {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      a {
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: $color-primary;
          transition: all 400ms;
        }
      }
    }

    button {
      // border-radius: 24px;
      // border: none;
      // font-weight: 500;
      // font-size: 16px;
      // background-color: transparent;
      // overflow: hidden;
      // position: relative;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        padding: 16px 24px;
        border-radius: 24px;
        border: none;
        height: 100%;
        width: 100%;

      }

      .btn-face-white {
        color: black;
        background-color: gray;
        z-index: 998;
      }

      .btn-face-purple {
        display: none;
        z-index: 999;
        position: absolute;
        top: 0;
      }

      &:hover {
        .btn-face-white {
          display: none;
        }

        .btn-face-white {
          display: flex;
          animation: appear 300ms linear 15 0s forwards;

        }
      }
    }

  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
    width: 100%;
    padding: 32px;

    .heading-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-end;
      width: 60%;

      :first-child {
        align-self: flex-start;
      }
      h2 {
        font-size: 62px;
        font-weight: 800;

        &:first-of-type {
          align-self: flex-start;
        }
      }
    }

    .bubble {
      background-color: hsla(0, 0%, 100%, 0.1);
      padding: 12px 14px;
      border-radius: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      &.fire {
        right: 20px;
        transform: rotate(-20deg);
        bottom: 30%;
        font-size: 24px;
        width: 50px;
        height: 50px;
      }

      &.rocket {
        top: 20%;
        right: 100px;
        font-size: 42px;
        width: 80px;
        height: 80px;
      }

      &.target {
        left: 150px;
        top: 10%;
        font-size: 72px;
        // transform: rotate(30deg);
        animation: balance 1000ms alternate 200ms infinite;
        width: 120px;
        height: 120px;
      }

      &.shop {
        bottom: 20%;
        left: 5%;
        font-size: 36px;
        transform: rotate(30deg);
        width: 70px;
        height: 70px;
      }
    }
  }

  .container-links {
    color: white;
    &:hover {
      filter: invert(19%) sepia(9%) saturate(70%) hue-rotate(131deg) brightness(86%) contrast(91%);
    }
  }
}

@media (max-width: 767px) {
  main {
    padding-left: 20px;
    padding-right: 20px;
  }
}
